@import '../../mixins';
@import '../../../pages/account/mixins';

$loyalty-gutter: 8px;

.loyalty-registration {
  @include loyalty-box;

  &.transfer-social-account-form {
    .form-element__input, .form-element__label {
      opacity: 0.3;
      border-bottom-color: var(--black);
      color: var(--black);
    }
  }

  [data-step] {
    display: none;
  }

  &__step {
    &[data-step="1"] {
      .loyalty-registration__action {
        margin-top: 0;
      }
    }
  }

  &__step {
    &[data-step="2"] {
      .label-mandatory-fields {
        margin-bottom: 1rem;
      }
    }
  }

  .text-big {
    color: var(--black);
  }

  .loyalty-registration__step {
    .text-big {
      color: var(--black);
    }

    .form-element__input {
      border-bottom-color: var(--black);
    }

    .form-element__input, .form-element__label_classic, .form-element__label, .form-group__description {
      color: var(--black);
    }

    .form-element__label_classic, .form-group__description {
      font-size: 1.4rem;
    }

    .form-group__description {
      a {
        color: var(--black);
      }
    }

    .show-password-button {
      svg {
        fill: var(--black);
      }
    }

    .checkbox__container .form-element__checkbox, .radio__container .form-element__radio {
      border-color: var(--black);
    }

    .custom-control-input.form-element__checkbox:checked {
      background-color: var(--black);
    }

    .group-prefix-phone {
      .form-element {
        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }

      .form-element:first-child {
        .form-element__error {
          display: none;
        }
      }

      .form-element:last-child {
        .form-element__error {
          transform: translateX(-78px);
        }
      }

    }

    .form-birthday .form-element{
      margin-bottom: 0;
    }

    .form-registration-password .form-element {
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    .form-registration-password-confirm .form-element {
        margin-bottom: 0;
    }

    .label-mandatory-fields {
      margin-left: 0.8rem;
      margin-right: 0.8rem;
    }
  }

  @for $i from 1 through 3 {
    &[data-active-step='#{$i}'] {
      [data-step='#{$i}'] {
        display: block;
      }
    }
  }

  fieldset {
    border: none;
    padding: 0;

    @include media-breakpoint-up(lg) {
      margin-top: 2.2rem;
    }

    .form-control,
    .privacy-label {
      color: var( --black);
      font-size: 1.4rem;
    }
  }

  &__action {
    display: flex;
    width: 100%;
    margin-top: 40px;
  }

  &__row {
    margin-left: -#{$loyalty-gutter};
    margin-right: -#{$loyalty-gutter};

    [class*='col-'] {
      padding-left: #{$loyalty-gutter};
      padding-right: #{$loyalty-gutter};
    }
  }

  &__progress {
    color: var(--main-color);
    text-align: center;
    font-size: 1.2rem;
    line-height: 1;
    padding: 1.4rem;
    margin-top: 0.6rem;
    position: relative;

    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      background-color: rgba(#e4002b, 0.2);
    }

    &::before {
      width: 8.8rem;
      height: 0.1rem;
      transform: translateX(-50%);
    }

    &::after {
      height: 0.2rem;
      background-color: #e4002b;
    }

    &--1-of-2 {
      &::after {
        transform: translateX(-100%);
        width: 4.4rem;
      }
    }

    &--2-of-2 {
      &::after {
        transform: translateX(-50%);
        width: 8.8rem;
      }
    }
  }
}

.loyalty-register-page__benefits {
  p, .loyalty-register__benefit {
    color: var(--back);
  }
}
